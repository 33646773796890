import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.6866353813803!2d17.939635416075355!3d59.40494048168535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9eedec2fb48d%3A0x4f51fa59d7a5a1c3!2sBorgarfjordsgatan%2021%2C%20164%2053%20Kista!5e0!3m2!1sen!2sse!4v1646862866180!5m2!1sen!2sse" width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>
        </>
    )
}

export default GoogleMap;  