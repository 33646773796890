
import React from 'react'; 
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import ContactImg from "../../assets/images/contact-img.png";



const ContactForm = () => {
  // Initiate forms
  const { register, handleSubmit } = useForm();

  // Transforms the form data from the React Hook Form output to a format Netlify can read
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  // Handles the post process to Netlify so we can access their serverless functions
  
  const [isSubmited, setSubmitted] = React.useState(false);  
  const onSubmit = (data, e) => {
    e.preventDefault()
    fetch(`https://api.codeofduty.se/v2/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body:JSON.stringify(data)
      })
        .then((response) => {
            setSubmitted(true)
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
  };
  const onError = (errors, e) => console.log(errors, e);


  return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Get In Touch With Us</h2>
                    <div className="bar"></div>
                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <img src={ContactImg} alt="image" />
                    </div>

                    <div className="col-lg-6 col-md-12">
                    <form onSubmit={handleSubmit(onSubmit, onError)}
                                name="contact-form" method="POST" 
                                data-netlify="true" netlify-honeypot="got-ya">
                            <input type="hidden" name="form-name" value="contact-form" {...register("form-name")}/>
                            <input type="hidden" name="formId" value="contact-form" {...register("form-id")}/>
                            
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            placeholder="Your Name" 
                                            className="form-control" {...register("name")}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="email" 
                                            placeholder="Your email address" 
                                            className="form-control"  {...register("email")}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="Phone" 
                                            placeholder="Your phone number" 
                                            className="form-control"  {...register("Phone")}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="subject" 
                                            placeholder="Your Subject" 
                                            className="form-control"   {...register("subject")}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea 
                                            name="text" 
                                            cols="30" 
                                            rows="5" 
                                            placeholder="Write your message..." 
                                            className="form-control"   {...register("message")}
                                        /> 
                                    </div>
                                </div>
            
                                <div className="col-lg-12 col-sm-12">
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;  
